import React, { useEffect } from 'react'


import Savona_fiori from '../assets/img/savona_fiori.JPG'

export default function About() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <section className="intro-single">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-8">
                            <div className="title-single-box">
                                <h1 className="title-single">Stile ed Eleganza a misura di casa.</h1>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-box d-flex justify-content-lg-end">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        About
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>{/* End Intro Single*/}
            {/* ======= About Section ======= */}
            <section className="section-about">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 position-relative">
                            <div className="about-img-box">
                                <img src={Savona_fiori} alt="" className="img-cover" />
                            </div>
                            <div className="sinse-box">
                                <h3 className="sinse-title">AlphaTech
                                    <span />
                                    <br />
                                    <br />
                                </h3>
                                <p>Art &amp; Style</p>
                            </div>
                        </div>
                        <div className="col-md-12 section-t8 position-relative">
                            <div className="row">
                                <div className="col-md-4 col-lg-4">
                                </div>
                                <div className="col-lg-2  d-none d-lg-block position-relative">
                                    <div className="title-vertical d-flex justify-content-start">
                                        <span>AlphaTech Exclusive Property</span>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5 section-md-t3">
                                    <div className="title-box-d">
                                        <h3 className="title-d">Il nostro
                                            <span className="color-d"> obbiettivo</span>
                                        </h3>
                                    </div>
                                    <div className="text-description">
                                        <p className="color-text-a">
                                            Quando decidiamo di ristrutturare un immobile abbiamo estrema cura di realizzarlo in modo tale che
                                            ogni aspettativa possa essere soddisfatta.
                                        </p>
                                        <p className="color-text-a">
                                            Ci preoccupiamo che le nostre unità immobiliari siano curate nei dettagli e che vengano equipaggiate
                                            con mobiliature e arredi che armonizzino gli spazi disponibili.
                                        </p>
                                        <p className="color-text-a">
                                            Il processo di ristrutturazione degli immobili include una serie di professionisti che abbiano le
                                            capacità di realizzare degli spazi eleganti e altamente vivibili.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>)
}
