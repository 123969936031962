import React from 'react'
import { NavLink } from 'react-router-dom'
import Logo from '../assets/img/AT_logo_3_blu.svg'

export default function Navbar() {
    return (
        <nav className="navbar navbar-default navbar-trans navbar-expand-lg fixed-top">
            <div className="container">
                <a className="navbar-brand" href="/">
                    <img src={Logo} className="nav-logo" />
                </a>
                <div className="navbar-collapse collapse justify-content-end" id="navbarDefault">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <NavLink to='/' activeClassName="active" exact={true}>
                                <a className="nav-link" href="/">Home</a>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to='/about' activeClassName="active">
                                <a className="nav-link " href="/#/about">Info</a>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to='/contact' activeClassName="active">
                                <a className="nav-link " href="/#/about">Contattaci</a>
                            </NavLink>
                        </li>
                        {/* <li className="nav-item">
                            <a className="nav-link " href="/#/properties">Proprietà</a>
                        </li> */}
                    </ul>
                </div>
                <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarDefault" aria-controls="navbarDefault" aria-expanded="false" aria-label="Toggle navigation">
                    <span />
                    <span />
                    <span />
                </button>
            </div>
        </nav>
    )
}
