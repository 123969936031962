import React, { useEffect } from 'react'

export default function Contact() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <section className="intro-single">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-8">
                            <div className="title-single-box">
                                <h1 className="title-single">Contattaci</h1>
                                <span className="color-text-a"> <div className="col-md-5 section-md-t3">
                                    <div className="icon-box section-b2">
                                        <div className="icon-box-icon">
                                            <span className="bi bi-envelope" />
                                        </div>
                                        <div className="icon-box-content table-cell">
                                            <div className="icon-box-title">
                                                <h4 className="icon-title">Email:</h4>
                                            </div>
                                            <div className="icon-box-content">
                                                <p className="mb-1">
                                                    <span className="color-a">
                                                        <a href="mailto:info@alpha-tech.it"> info@alpha-tech.it</a></span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="icon-box section-b2">
                                        <div className="icon-box-icon">
                                            <span className="bi bi-geo-alt" />
                                        </div>
                                        <div className="icon-box-content table-cell">
                                            <div className="icon-box-title">
                                                <h4 className="icon-title">Sede Legale</h4>
                                            </div>
                                            <div className="icon-box-content">
                                                <p className="mb-1">
                                                    Via Savona 70, Milano
                                                    <br /> 20144
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="icon-box">
                                        <div className="icon-box-icon">
                                            <span className="bi bi-share" />
                                        </div>
                                        <div className="icon-box-content table-cell">
                                            <div className="icon-box-title">
                                                <h4 className="icon-title">Social networks</h4>
                                            </div>
                                            <div className="icon-box-content">
                                                <div className="socials-footer">
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item">
                                                            <a href="https://www.linkedin.com/company/alphatechmilano/about/" className="link-one">
                                                                <i className="bi bi-linkedin" aria-hidden="true" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div></span>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-box d-flex justify-content-lg-end">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="index.html">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Contact
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>{/* End Intro Single*/}
            {/* ======= Contact Single ======= */}
            <section className="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 section-t8">
                            <div className="row">

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
