import React from 'react'
import { GloriousCrew } from '../Contacts.js';
import GC_logo from "../assets/img/GloriousCrew.jpeg"

export default function GloriousCrewContact() {
    return (
        <div>
            <div className="col-md-12">
                <div className="row section-t3">
                    <div className="col-sm-12">
                        <div className="title-box-d">
                            <h3 className="title-d">Contatti Agenzia</h3>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-6 col-lg-4">
                        <a href="https://www.gloriouscrew.com/">
                            <img src={GC_logo} alt className="img-fluid gc-logo" />
                        </a>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <div className="property-agent">
                            <h4 className="title-agent">{GloriousCrew.NAME}</h4>
                            <p className="color-text-a">
                                Glorious Crew sarà lieta di organizzarvi una visita dell'immobile e di aiutarvi nel processo di affitto.
                            </p>
                            <ul className="list-unstyled">
                                <li className="d-flex justify-content-between">
                                    <strong>Tel:</strong>
                                    <a href={`tel:${GloriousCrew.TEL}`}>{GloriousCrew.TEL}</a>
                                </li>
                                <li className="d-flex justify-content-between">
                                    <strong>Email:</strong>
                                    <a href={`mailto:${GloriousCrew.EMAIL}`}> {GloriousCrew.EMAIL}</a>
                                </li>
                                <li className="d-flex justify-content-between">
                                    <strong>Website:</strong>
                                    <a href={`https://${GloriousCrew.WWW}`}> {GloriousCrew.WWW}</a>
                                </li>
                            </ul>
                            <div className="socials-a">
                                <ul className="list-inline">
                                    <li className="list-inline-item">
                                        <a href={`https://${GloriousCrew.WWW}`}>
                                            <i className="bi bi-globe" aria-hidden="true" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href={`https://${GloriousCrew.FB}`}>
                                            <i className="bi bi-facebook" aria-hidden="true" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href={`https://${GloriousCrew.IG}`}>
                                            <i className="bi bi-instagram" aria-hidden="true" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href={`https://${GloriousCrew.LINKEDIN}`}>
                                            <i className="bi bi-linkedin" aria-hidden="true" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-12 col-lg-4">
                                    <div className="property-contact">
                                        <form className="form-a">
                                            <div className="row">
                                                <div className="col-md-12 mb-1">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-lg form-control-a"
                                                            id="inputName"
                                                            placeholder="Name *"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-1">
                                                    <div className="form-group">
                                                        <input
                                                            type="email"
                                                            className="form-control form-control-lg form-control-a"
                                                            id="inputEmail1"
                                                            placeholder="Email *"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-1">
                                                    <div className="form-group">
                                                        <textarea
                                                            id="textMessage"
                                                            className="form-control"
                                                            placeholder="Comment *"
                                                            name="message"
                                                            cols={45}
                                                            rows={8}
                                                            required
                                                            defaultValue={""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-3">
                                                    <button type="submit" className="btn btn-a">
                                                        Send Message
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div> */}
                </div>
            </div>
        </div>
    )
}
