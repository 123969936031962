import Navbar from './components/Navbar';
import Home from './containers/Home';
import About from './containers/About';
import Footer from './components/Footer';
import './App.css';
import React, { useState } from 'react'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import PropertyDetail from './containers/PropertyDetail';
import Contact from './containers/Contact';

function App() {
  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false)
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  window.addEventListener('scroll', checkScrollTop)
  return (
    <div className="App">
      <Router basename="/">
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/about" component={About}></Route>
          <Route exact path="/propertydetail/:id" component={PropertyDetail}></Route>
          <Route exact path="/contact" component={Contact}></Route>
        </Switch>
        <Footer />
      </Router>
      <a className={`back-to-top d-flex align-items-center justify-content-center ${showScroll ? "active" : null}`}
        onClick={scrollTop}><i
          className="bi bi-arrow-up-short"></i></a>
    </div>
  );
}

export default App;
