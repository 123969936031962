import React from 'react'

import Bilo3_home from '../assets/img/Bilo3/Bilo3-3.jpg'
import ComingSoon from '../assets/img/s70-outside.jpg'
import Bilo3_slide from '../assets/img/Bilo3/Bilo3-2.jpg'
import Bilo_ComingSoon from '../assets/img/savona_fiori.JPG'
import { GloriousCrew } from '../Contacts.js';
import GC_logo from "../assets/img/GloriousCrew.jpeg"

import { Apartments } from "../Apartments.js"

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css';
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

import SwiperCore, {
    Autoplay, Pagination, Navigation
} from 'swiper/core';
import GloriousCrewContact from '../components/GloriousCrewContact'

SwiperCore.use([Autoplay, Pagination, Navigation]);

function CreateIntroSlider(Background_uri, City, CAP, Str_num, Street, comingSoon) {
    return (
        <div className="carousel-item-a intro-item bg-image" style={{ backgroundImage: `url(${Background_uri})` }}>
            <div className="overlay overlay-a" ></div>
            <div className="intro-content display-table">
                <div className="table-cell">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="intro-body">
                                    <p className="intro-title-top">{City}
                                        <br /> {CAP}
                                    </p>
                                    <h1 className="intro-title mb-4 ">
                                        <span className="color-b">{Str_num}</span>
                                        <br />{Street}
                                    </h1>
                                    <p className="intro-subtitle intro-price">
                                        {comingSoon === false ?
                                            <span className="price-a" data-bs-toggle="modal" data-bs-target="#exampleModal"><a >
                                                Contatta agenzia
                                            </a></span> :
                                            <span className="price-a">Nuovi appartamenti a breve</span>}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}

function CreatePropertySlide(ID = 0, Background_uri, Street, City, name, area, beds, bathrooms, garages, comingSoon) {
    return (

        <div className="card-box-a card-shadow" onClick={() => { window.location.gref = 'https://www.alpha-tech.it/#/propertydetail/2' }}>
            <div className="img-box-a">
                <img src={Background_uri} alt="" className="img-a img-fluid" />
            </div>
            <div className="card-overlay">
                <div className="card-overlay-a-content">
                    <div className="card-header-a">
                        <h2 className="card-title-a">
                            {comingSoon === false ? <span>{Street}
                                < br /> {City}</span> : <a href={`/#/propertydetail/${ID}`}>{Street}
                                <br /> {City}</a>}

                        </h2>
                        <h4 className="card-title-b">{name}</h4>
                    </div>
                    <div className="card-body-a">
                        {comingSoon === true ? <a href={`/#/propertydetail/${ID}`} className="link-a">Clicca per vedere di più
                            <span className="bi bi-chevron-right" />
                        </a> : null}
                    </div>
                    <div className="card-footer-a">
                        <ul className="card-info d-flex justify-content-around">
                            <li>
                                <h4 className="card-info-title">Area</h4>
                                <span>{area}m
                                    <sup>2</sup>
                                </span>
                            </li>
                            <li>
                                <h4 className="card-info-title">Letti</h4>
                                <span>{beds}</span>
                            </li>
                            <li>
                                <h4 className="card-info-title">Bagni</h4>
                                <span>{bathrooms}</span>
                            </li>
                            <li>
                                <h4 className="card-info-title">Garage</h4>
                                <span>{garages}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div >
    );
}


export default function Home() {
    return (
        <div>
            <Swiper className="intro intro-carousel position-relative" speed={700} spaceBetween={30} centeredSlides={true} loop={true} autoplay={{
                "delay": 4000,
                "disableOnInteraction": false
            }} pagination={{
                "type": 'bullets',
                "clickable": true
            }} navigation={false}>
                <SwiperSlide>{CreateIntroSlider(Bilo3_home, "Milano", 20144, 70, "via savona", false)}</SwiperSlide>
                <SwiperSlide>{CreateIntroSlider(ComingSoon, "Milano", 20144, 70, "via savona", true)}</SwiperSlide>
            </Swiper>

            <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="title-d">Contatti Agenzia</h3>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div className="row justify-content-center">
                                <div className="col-md-6 col-lg-4">
                                    <a href="https://www.gloriouscrew.com/">
                                        <img src={GC_logo} alt className="img-fluid gc-logo" />
                                    </a>
                                </div>
                                <div className="col-md-6 col-lg-4 my-auto">
                                    <div className="property-agent">
                                        <h4 className="title-agent">{GloriousCrew.NAME}</h4>
                                        <p className="color-text-a">
                                            Glorious Crew sarà lieta di organizzarvi una visita dell'immobile e di aiutarvi nel processo di affitto.
                                        </p>
                                        <ul className="list-unstyled">
                                            <li className="d-flex justify-content-between">
                                                <strong>Tel:</strong>
                                                <a href={`tel:${GloriousCrew.TEL}`}>{GloriousCrew.TEL}</a>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <strong>Email:</strong>
                                                <a href={`mailto:${GloriousCrew.EMAIL}`}> {GloriousCrew.EMAIL}</a>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <strong>Website:</strong>
                                                <a href={`https://${GloriousCrew.WWW}`}> {GloriousCrew.WWW}</a>
                                            </li>
                                        </ul>
                                        <div className="socials-a">
                                            <ul className="list-inline">
                                                <li className="list-inline-item">
                                                    <a href={`https://${GloriousCrew.WWW}`}>
                                                        <i className="bi bi-globe" aria-hidden="true" />
                                                    </a>
                                                </li>
                                                <li className="list-inline-item">
                                                    <a href={`https://${GloriousCrew.FB}`}>
                                                        <i className="bi bi-facebook" aria-hidden="true" />
                                                    </a>
                                                </li>
                                                <li className="list-inline-item">
                                                    <a href={`https://${GloriousCrew.IG}`}>
                                                        <i className="bi bi-instagram" aria-hidden="true" />
                                                    </a>
                                                </li>
                                                <li className="list-inline-item">
                                                    <a href={`https://${GloriousCrew.LINKEDIN}`}>
                                                        <i className="bi bi-linkedin" aria-hidden="true" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-md-12 col-lg-4">
                                    <div className="property-contact">
                                        <form className="form-a">
                                            <div className="row">
                                                <div className="col-md-12 mb-1">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-lg form-control-a"
                                                            id="inputName"
                                                            placeholder="Name *"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-1">
                                                    <div className="form-group">
                                                        <input
                                                            type="email"
                                                            className="form-control form-control-lg form-control-a"
                                                            id="inputEmail1"
                                                            placeholder="Email *"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-1">
                                                    <div className="form-group">
                                                        <textarea
                                                            id="textMessage"
                                                            className="form-control"
                                                            placeholder="Comment *"
                                                            name="message"
                                                            cols={45}
                                                            rows={8}
                                                            required
                                                            defaultValue={""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-3">
                                                    <button type="submit" className="btn btn-a">
                                                        Send Message
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div> */}
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Chiudi</button>
                        </div>
                    </div>
                </div>
            </div>



            <main id="main">

                <section className="section-property section-t8">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="title-wrap d-flex justify-content-between">
                                    <div className="title-box">
                                        <h2 className="title-a">Le nostre case</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Swiper slidesPerView={'auto'} speed={700} loop={true} loopFillGroupWithBlank={true}
                            autoplay={{
                                "delay": 3500,
                                "disableOnInteraction": false
                            }} pagination={{
                                "el": '.property-carousel-pagination',
                                "type": 'bullets',
                                "clickable": true
                            }} breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 20
                                },

                                1200: {
                                    slidesPerView: 3,
                                    spaceBetween: 20
                                }
                            }
                            } navigation={false}>
                            {Apartments.map((apartment, i) => (
                                <SwiperSlide className="carousel-item-b">{
                                    CreatePropertySlide(i, apartment.SMALL_IMG, `${apartment.STRN} ${apartment.ADDR}`, apartment.CITY, apartment.NAME, apartment.AREA, apartment.BEDS, apartment.BATHROOMS, apartment.GARAGE, apartment.READY)}</SwiperSlide>
                            ))}
                        </Swiper>
                        <div className="property-carousel-pagination carousel-pagination swiper-pagination-clickable swiper-pagination-bullets">
                        </div>

                    </div>
                </section>{/* End Latest Properties Section */}
            </main>

        </div >
    )
}
