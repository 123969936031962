import React, { useEffect } from 'react'
import { useParams } from 'react-router';
import { Apartments } from '../Apartments.js'
import { Swiper, SwiperSlide } from 'swiper/react';
import "../App.css"
import GloriousCrewContact from '../components/GloriousCrewContact.js';


export default function PropertyDetail() {
    let { id } = useParams();
    let Apt = Apartments[id];
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <section className="intro-single">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-8">
                            <div className="title-single-box">
                                <h1 className="title-single">{Apt.STRN} {Apt.ADDR}</h1>
                                <span className="color-text-a">{Apt.CITY}, {Apt.CAP}</span>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-4">
                            <nav
                                aria-label="breadcrumb"
                                className="breadcrumb-box d-flex justify-content-lg-end"
                            >
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="index.html">Home</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a href="property-grid.html">Properties</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {Apt.NAME}
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Intro Single*/}

            {/* ======= Property Single ======= */}
            <section className="property-single nav-arrow-b">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <Swiper id="property-single-carousel" speed={600} spaceBetween={30} centeredSlides={true} loop={true} autoplay={{
                                "delay": 2500,
                                "disableOnInteraction": false
                            }} pagination={{
                                "el": ".property-carousel-pagination",
                                "type": 'bullets',
                                "clickable": true
                            }} navigation={false}>
                                {Apt.IMG.map(img => (
                                    <SwiperSlide><img className="img-fluid property-detail" src={img} alt /></SwiperSlide>
                                ))}
                            </Swiper>
                            <div className="property-carousel-pagination carousel-pagination swiper-pagination-clickable swiper-pagination-bullets">
                            </div>
                        </div>
                    </div>
                    <div className="row" id="property-description">
                        <div className="col-sm-12">
                            <div className="row justify-content-between">
                                <div className="col-md-5 col-lg-4">
                                    <div className="property-price d-flex justify-content-center foo">
                                        <div className="card-header-c d-flex">
                                            <div className="card-box-ico">
                                                <span>{Apt.READY ? "Disponibile" : "Non Disponibile"}</span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="property-summary">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="title-box-d section-t4">
                                                    <h3 className="title-d">Sommario</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="summary-list">
                                            <ul className="list">
                                                <li className="d-flex justify-content-between">
                                                    <strong>Property ID:</strong>
                                                    <span>{id}</span>
                                                </li>
                                                <li className="d-flex justify-content-between">
                                                    <strong>Location:</strong>
                                                    <span>{Apt.CITY}, {Apt.CAP}</span>
                                                </li>
                                                <li className="d-flex justify-content-between">
                                                    <strong>Tipo appartamento:</strong>
                                                    <span>{Apt.TYPE}</span>
                                                </li>
                                                <li className="d-flex justify-content-between">
                                                    <strong>Status:</strong>
                                                    <span>{Apt.READY ? "Disponibile" : "Non Disponibile"}</span>
                                                </li>
                                                <li className="d-flex justify-content-between">
                                                    <strong>Area:</strong>
                                                    <span>
                                                        {Apt.AREA}m
                                                        <sup>2</sup>
                                                    </span>
                                                </li>
                                                <li className="d-flex justify-content-between">
                                                    <strong>Letti:</strong>
                                                    <span>{Apt.BEDS}</span>
                                                </li>
                                                <li className="d-flex justify-content-between">
                                                    <strong>Bagni:</strong>
                                                    <span>{Apt.BATHROOMS}</span>
                                                </li>
                                                <li className="d-flex justify-content-between">
                                                    <strong>Garage:</strong>
                                                    <span>{Apt.GARAGE}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7 col-lg-7 section-md-t3">
                                    <div className="row">
                                        <div className="col-sm-12" style={{ paddingTop: 'calc(2vh + 5px)' }}>
                                            <div className="title-box-d">
                                                <h3 className="title-d">Descrizione proprietà</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-description">
                                        <p className="description color-text-a">
                                            {Apt.DESCR1}
                                        </p>
                                        <p className="description color-text-a">
                                            {Apt.DESCR2}
                                        </p>
                                        <p className="description color-text-a">
                                            {Apt.DESCR3}
                                        </p>
                                    </div>
                                    <div className="row section-t3">
                                        <div className="col-sm-12">
                                            <div className="title-box-d">
                                                <h3 className="title-d">Servizi</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="amenities-list color-text-a">
                                        <ul className="list-a no-margin">
                                            {Apt.SERVIZI.map(servizio => (<li>{servizio}</li>))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-10 offset-md-1">
                            <ul
                                className="nav nav-pills-a nav-pills mb-3 section-t3"
                                id="pills-tab"
                                role="tablist"
                            >
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        id="pills-map-tab"
                                        data-bs-toggle="pill"
                                        href="#pills-map"
                                        role="tab"
                                        aria-controls="pills-map"
                                        aria-selected="true"
                                    >
                                        Posizione
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        id="pills-plans-tab"
                                        data-bs-toggle="pill"
                                        href="#pills-plans"
                                        role="tab"
                                        aria-controls="pills-plans"
                                        aria-selected="false"
                                    >
                                        Piantina
                                    </a>
                                </li>

                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div class="tab-pane show active fade text-center" id="pills-map" role="tabpanel" aria-labelledby="pills-map-tab">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2798.8358438793584!2d9.156328715557057!3d45.45296397910087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c3e7a47d54a3%3A0xf533bbd0d2abfec6!2sVia%20Savona%2C%2070%2C%2020144%20Milano%20MI!5e0!3m2!1sen!2sit!4v1627499627356!5m2!1sen!2sit"
                                        width="100%"
                                        height={460}
                                        frameBorder={0}
                                        style={{ border: 0 }}
                                        allowFullScreen />
                                </div>
                                <div
                                    className="tab-pane fade text-center"
                                    id="pills-plans"
                                    role="tabpanel"
                                    aria-labelledby="pills-plans-tab"
                                >
                                    <img src={Apt.PIANTINA} alt className="img-fluid property-piantina" />
                                </div>

                            </div>
                        </div>
                        <GloriousCrewContact></GloriousCrewContact>
                    </div>
                </div>
            </section>
        </div>)
}
