import React from 'react'

export default function Footer() {
    return (
        <div>
            <section className="section-footer text-center">
                <div className="container">
                    <div className="row ">
                        <div className="col-sm-12 col-md-6">
                            <div className="widget-a">
                                <div className="w-header-a">
                                    <h3 className="w-title-a text-brand">AlphaTech</h3>
                                </div>
                                <div className="w-body-a">
                                    <p className="w-text-a color-text-a">
                                        A real estate business based in Milan
                                    </p>
                                </div>
                                <div className="w-footer-a">
                                    <ul className="list-unstyled">
                                        <li className="color-a">
                                            <span className="color-text-a">Email  <a href="mailto:info@alpha-tech.it"> info@alpha-tech.it</a></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 section-md-t3">
                            <div className="widget-a">
                                <div className="w-header-a">
                                    <h3 className="w-title-a text-brand">The Company</h3>
                                </div>
                                <div className="w-body-a">
                                    <div className="w-body-a">
                                        <ul className="list-unstyled">
                                            <li className="item-list-a">
                                                <p>P.iva: <br />11387140152</p>
                                            </li>
                                            <li className="item-list-a">
                                                <p>Sede Legale: <br />  Via Savona 70, 20144 Milano</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <nav className="nav-footer">
                                <ul className="list-inline">
                                    <li className="list-inline-item">
                                        <a href="/">Home</a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="/#/about">Info</a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="/#/contact">Contatti</a>
                                    </li>
                                </ul>
                            </nav>
                            <div className="socials-a">
                                <ul className="list-inline">
                                    {/* <li className="list-inline-item">
                                        <a href="#">
                                            <i className="bi bi-facebook" aria-hidden="true" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="#">
                                            <i className="bi bi-twitter" aria-hidden="true" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="#">
                                            <i className="bi bi-instagram" aria-hidden="true" />
                                        </a>
                                    </li> */}
                                    <li className="list-inline-item">
                                        <a href="https://www.linkedin.com/company/alphatechmilano/about/">
                                            <i className="bi bi-linkedin" aria-hidden="true" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
