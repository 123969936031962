import Coming_BIG_IMG from "./assets/img/s70-outside.jpg"
import Coming_SMALL_IMG from "./assets/img/savona_fiori.JPG"

import Bilo2_IMG1 from "./assets/img/Bilo2/Bilo2-1.jpg"
import Bilo2_IMG2 from "./assets/img/Bilo2/Bilo2-2.jpg"
import Bilo2_IMG3 from "./assets/img/Bilo2/Bilo2-3.jpg"
import Bilo2_IMG4 from "./assets/img/Bilo2/Bilo2-5.jpg"
import Bilo2_IMG5 from "./assets/img/Bilo2/Bilo2-6.jpg"
import Bilo2_IMG6 from "./assets/img/Bilo2/Bilo2-7.jpg"
import Bilo2_IMG7 from "./assets/img/Bilo2/Bilo2-8.jpg"

import Bilo2_Piantina from "./assets/img/Bilo2/Piantina.jpg"

import Bilo3_IMG1 from "./assets/img/Bilo3/Bilo3-3.jpg"
import Bilo3_IMG2 from "./assets/img/Bilo3/Bilo3-2.jpg"
import Bilo3_IMG3 from "./assets/img/Bilo3/Bilo3-7.jpg"
import Bilo3_IMG4 from "./assets/img/Bilo3/Bilo3-8.jpg"
import Bilo3_IMG5 from "./assets/img/Bilo3/Bilo3-10.jpg"
import Bilo3_IMG6 from "./assets/img/Bilo3/Bilo3-13.jpg"

import Bilo3_Piantina from "./assets/img/Bilo3/Piantina.jpg"


export let Apartments = [
    {
        ADDR: "Via Savona",
        STRN: "70",
        CITY: "Milano",
        CAP: "20144",
        READY: false,
        BIG_IMG: Coming_BIG_IMG,
        SMALL_IMG: Coming_SMALL_IMG,
        IMG: [],
        NAME: `Bilocale "Frederick", Arredato`,
        DESCR1: "", DESCR2: "", DESCR3: "",
        AREA: "60",
        BEDS: "1",
        BATHROOMS: "1",
        GARAGE: "Su richiesta",
        SERVIZI: []
    },
    {
        ADDR: "Via Savona",
        STRN: "70",
        CITY: "Milano",
        CAP: "20144",
        READY: true,
        BIG_IMG: Bilo2_IMG1,
        SMALL_IMG: Bilo2_IMG2,
        IMG: [Bilo2_IMG1, Bilo2_IMG2, Bilo2_IMG3, Bilo2_IMG4, Bilo2_IMG5, Bilo2_IMG6, Bilo2_IMG7],
        PIANTINA: Bilo2_Piantina,
        NAME: `Bilocale "Alexander", Arredato`,
        DESCR1: "Questo ampio bilocale di 60 mq circa di nuova realizzazione è situato in posizione strategica nella zona rinomata del Design District e dei vivaci e raffinati locali alla moda. L'appartamento, recentemente ristrutturato è situato in un condominio moderno ed elegante, caratterizzato dalle facciate in mattoni rossi. Il condominio offre un  giardino privato e  servizio di portineria. ",
        DESCR2: "La residenza è caratterizzata da finiture di pregio come il pavimento in parquet in rovere, ampie vetrate, arredamento di design (poltrone e pouf Chester di Poltrona Frau, libreria Original Ptolomeo di Opinion Ciatti, lampade Fontana Arte) ed armadiature su misura. La cucina, di Cappellini Cucine, è completa di accessori ed elettrodomestici. Il bagno è spazioso, con sanitari sospesi e comoda doccia. L'aria condizionata, il riscaldamento e l'acqua calda sono autonomi. L'immobile è posto al primo piano, con ascensore, ed ha un terrazzo loggiato ampio e vivibile su cui affaccia sia il soggiorno sia la camera da letto. Varcando la soglia della porta blindata si accede alla residenza caratterizzata da finiture di pregio come il pavimento in parquet in rovere, ampie vetrate, arredamento di design ed armadiature su misura. La cucina, di Cappellini Cucine, è completa di accessori ed elettrodomestici. Il bagno è spazioso, con sanitari sospesi e comoda doccia. L'aria condizionata, il riscaldamento e l'acqua calda sono autonomi.",
        DESCR3: "La zona è molto ben servita dai mezzi pubblici di superficie e la fermata della metropolitana MM2 Linea Verde Porta Genova si trova a breve distanza. Disponibile nello stabile box singolo in locazione della medesima Proprietà.",
        AREA: "60",
        BEDS: "1",
        BATHROOMS: "1",
        GARAGE: "Su richiesta",
        SERVIZI: [
            "Cucina completa",
            "Porta Blindata",
            "Balcone arredato",
            "Impianto CDZ",
            "Parquet",
            "Portineria",
            "Ascensore",
            "Lavatrice",
            "TV 4K",
            "Nespresso",
            "Scaldasalviette"
        ]
    }
    , {
        TYPE: "Bilocale",
        ADDR: "Via Savona",
        STRN: "70",
        CITY: "Milano",
        CAP: "20144",
        READY: true,
        BIG_IMG: Bilo3_IMG1,
        SMALL_IMG: Bilo3_IMG2,
        IMG: [Bilo3_IMG1, Bilo3_IMG2, Bilo3_IMG3, Bilo3_IMG4, Bilo3_IMG5, Bilo3_IMG6],
        PIANTINA: Bilo3_Piantina,
        IMG_FOLDER: "Bilo3",
        NAME: `Bilocale "Camille", Arredato`,
        DESCR1: "Questo ampio bilocale di 60 mq circa di nuova realizzazione è situato in posizione strategica nella zona rinomata del Design District e dei vivaci e raffinati locali alla moda. L'appartamento, recentemente ristrutturato è situato in un condominio moderno ed elegante, caratterizzato dalle facciate in mattoni rossi. Il condominio offre un  giardino privato e  servizio di portineria. ",
        DESCR2: "La residenza è caratterizzata da finiture di pregio come il pavimento in parquet in rovere, ampie vetrate, arredamento di design (poltrone e pouf Chester di Poltrona Frau, libreria Original Ptolomeo di Opinion Ciatti, lampade Fontana Arte) ed armadiature su misura. La cucina, di Cappellini Cucine, è completa di accessori ed elettrodomestici. Il bagno è spazioso, con sanitari sospesi e comoda doccia. L'aria condizionata, il riscaldamento e l'acqua calda sono autonomi. L'immobile è posto al primo piano, con ascensore, ed ha un terrazzo loggiato ampio e vivibile su cui affaccia sia il soggiorno sia la camera da letto.",
        DESCR3: "La zona è molto ben servita dai mezzi pubblici di superficie e la fermata della metropolitana MM2 Linea Verde Porta Genova si trova a breve distanza. Disponibile nello stabile box singolo in locazione.",
        AREA: "60",
        BEDS: "1",
        BATHROOMS: "1",
        GARAGE: "Su richiesta",
        SERVIZI: [
            "Cucina completa",
            "Porta Blindata",
            "Balcone",
            "Impianto CDZ",
            "Parquet",
            "Portineria",
            "Ascensore",
            "Lavatrice",
            "Asciugatrice",
            "TV 4K",
            "Nespresso",
            "Scaldasalviette"
        ]
    },
    {
        ADDR: "Via Savona",
        STRN: "70",
        CITY: "Milano",
        CAP: "20144",
        READY: false,
        BIG_IMG: Coming_BIG_IMG,
        SMALL_IMG: Coming_SMALL_IMG,
        IMG: [],
        NAME: `Bilocale "Charles", Arredato`,
        DESCR1: "", DESCR2: "", DESCR3: "",
        AREA: "60",
        BEDS: "1",
        BATHROOMS: "1",
        GARAGE: "Su richiesta",
        SERVIZI: []
    },
    {
        ADDR: "Via Savona",
        STRN: "70",
        CITY: "Milano",
        CAP: "20144",
        READY: false,
        BIG_IMG: Coming_BIG_IMG,
        SMALL_IMG: Coming_SMALL_IMG,
        IMG: [],
        NAME: "Quadrilocale, Arredato",
        DESCR1: "", DESCR2: "", DESCR3: "",
        AREA: "130",
        BEDS: "3",
        BATHROOMS: "4",
        GARAGE: "Su richiesta",
        SERVIZI: []
    }
]